import type { MessageContext } from '@intlify/core-base'

export const i18nDefaults = {
  i18n: {
    countryname: () => `${useRuntimeConfig().public.i18nConfig.countryName}`,
    gamblinghotline: () =>
      `${useRuntimeConfig().public.i18nConfig.gamblingHotline}`,
    playnow: ({ linked }: MessageContext) => {
      const lt = normalizeKey(useRuntimeConfig().public.licensedTerritory)
      const path = 'common.words.playnow'

      return linked(`${path}.${lt}`) || linked(path)
    },
    requiredage: () => `${useRuntimeConfig().public.i18nConfig.requiredAge}`,
    shopname: () => `${useRuntimeConfig().public.i18nConfig.shopPublicName}`,
    statename: () => `${useRuntimeConfig().public.i18nConfig.stateName}`,
    supportemail: () => `${useRuntimeConfig().public.i18nConfig.supportEmail}`,
  },
}
