import { resolveValue } from '@intlify/core-base'

import enUS from '~/locales/en-US/translations.json'
import { i18nDefaults } from '~/locales/i18n.defaults'

export default defineI18nConfig(() => ({
  fallbackLocale: 'en-US',
  fallbackWarn: false,
  globalInjection: true,
  legacy: false,
  locales: ['en-US'],
  messageResolver: (translations: any, key) => {
    const normalizedKey = normalizeKey(key)
    return (
      translations[normalizedKey] || resolveValue(translations, normalizedKey)
    )
  },
  messages: {
    'en-US': {
      ...i18nDefaults,
      ...enUS,
    },
  },
  missingWarn: false,
}))
